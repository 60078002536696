<div class="featured-topics-host">
  <h1 class="title">Featured Topics</h1>
  <div class="featured-topics">
    <div class="topic">
      <div class="top">
        <h5 class="topic-title">Technology</h5>
        <button class="btn-primary" @click="searchVectorByTopic('technology')">View all</button>
      </div>
      <img src="../../assets/topics/tech.svg" />
    </div>
    <div class="topic">
      <div class="top">
        <h5 class="topic-title">Science</h5>
        <button class="btn-primary" @click="searchVectorByTopic('science')">View all</button>
      </div>
      <img src="../../assets/topics/science.svg" />
    </div>
    <div class="topic">
      <div class="top">
        <h5 class="topic-title">Objects</h5>
        <button class="btn-primary" @click="searchVectorByTopic('object')">View all</button>
      </div>
      <img src="../../assets/topics/objects.svg" />
    </div>
    <div class="topic">
      <div class="top">
        <h5 class="topic-title">Food</h5>
        <button class="btn-primary" @click="searchVectorByTopic('food')">View all</button>
      </div>
      <img src="../../assets/topics/food.svg" />
    </div>
    <div class="topic">
      <div class="top">
        <h5 class="topic-title">People</h5>
        <button class="btn-primary" @click="searchVectorByTopic('people')">View all</button>
      </div>
      <img src="../../assets/topics/people.svg" />
    </div>
    <div class="topic">
      <div class="top">
        <h5 class="topic-title">Charts</h5>
        <button class="btn-primary" @click="searchVectorByTopic('chart')">View all</button>
      </div>
      <img src="../../assets/topics/charts.svg" />
    </div>
    <div class="topic">
      <div class="top">
        <h5 class="topic-title">Emojis</h5>
        <button class="btn-primary" @click="searchVectorByTopic('emojis')">View all</button>
      </div>
      <img src="../../assets/topics/emojis.svg" />
    </div>
    <div class="topic">
      <div class="top">
        <h5 class="topic-title">Office</h5>
        <button class="btn-primary" @click="searchVectorByTopic('office')">View all</button>
      </div>
      <img src="../../assets/topics/office.svg" />
    </div>
    <div class="topic">
      <div class="top">
        <h5 class="topic-title">Social</h5>
        <button class="btn-primary" @click="searchVectorByTopic('social')">View all</button>
      </div>
      <img src="../../assets/topics/rrss.svg" />
    </div>
  </div>
</div>
