<div class="fonts-host">
  <h3 class="title">Fonts</h3>
  <p class="text">
    Do you want the letters or icons of Cocomaterial in your fave tool?
    Grab the Cocomaterial fonts and install them on your computer! Get
    ready for some serious coolness!
  </p>
  <div class="buttons-container">
    <a class="button" v-bind:href="lettersFont" download="">Download font</a>
    <a class="button" v-bind:href="iconsFont" download="">Download icons font</a>
  </div>
</div>
