<template src="./CocoFeaturedTopics.html" lang="html"></template>
<style src="./CocoFeaturedTopics.scss"  lang="scss" scoped></style>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CocoFeaturedTopics'
})
</script>
